import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { dialogBoxProps } from "../../ss-cms-common-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { Scrollbars } from "react-custom-scrollbars";
import { createRef } from "react"
import { isTokenExpired, clearStorageData, getBannerURL, generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { ProductPreviewContentInterface } from "./ProductPreviewScreenController.web";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { isJson } from "./helpers";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { HeaderMetadata, sectionData } from "./ContactInformationController.web";
import storageProvider from "../../../framework/src/StorageProvider.web";
const baseURL = require("../../../framework/src/config.js").baseURL;
import * as configcat from 'configcat-js';
import { configCatClient } from "../../../components/src/FeatureFlag";
import moment from "moment";
// Customizable Area End

export type Props = DialogProps &
  dialogBoxProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    classes?: {};
    onTextChange?: (value: string) => void;
    // Customizable Area End
  };

// Customizable Area Start
export interface S {
  selectedTab: string;
  createId: string;
  updateData: ProductPreviewContentInterface;
  isApiCalled: boolean;
  imageUrl: string;
  categoryData: Array<{ [key: string]: string }>;
  productData: Array<ProductData>;
  storeName: string;
  sectionCategoryTitle: { [key: string]: string };
  SectionData: SectionDataInterfaceType[],
  InitialSectionData: SectionDataInterfaceType[],
  token: token,
  isLoading: boolean
  selectedSection:string;
  expandedSection:string;
  servicesData: service[];
  staffData: StaffData[];
  categoriesData: any;
  isTestmonial: boolean;
  textLogoMetaData: { fontType: string, colour: string },
  removeLogo: boolean
  navigationItems: HeaderMetadata
  selectedColor: { primary: string, secondary: string, primarySecond: string, secondarySecond: string };
  selectedFonts: { header: string, body: string };
  storeEmail: string;
  buildCardID: string;
  countryName: string;
  isHeaderEnable: boolean;
  isBannerEnable: boolean;
  // updatedText: string;
  footerCopyRightError: string;
  currStoreName: string;
}

export interface ProductData {
  product_name: string,
  product_image: string,
  new_rate: string,
  old_rate: string,
  currency: string,
  discount: string
}

type token = string | null

export interface SectionsData {
  id: string,
  type: string,
  attributes: {
      section_type: string,
      section_name: null,
      banner_url: string,
      mobile_banner_url: string,
      mobile_banner_metadata: string,
      desktop_banner_url: string,
      desktop_banner_metadata: string,
      is_deleted: boolean,
      banner_filename: null,
      desktop_banner_filename: string,
      mobile_banner_filename: string,
      metadata: string,
      section_order: number,
      desktop_banner_variants: {
        url: string;
        webp_url: string;
        768: string;
        1280: string;
        1920: string;
      },
      mobile_banner_variants: {
        url: string;
        webp_url: string;
        360: string;
        414: string;
        601: string;
      }
  }
}

export interface SectionDataInterfaceType {
  type: string;
  id: string;
  is_deleted: boolean;
  sectionName?: string;
  mobileBannerUpload?: string;
  mobileBannerName?: string;  
  mobileBannerMetadata?: any;
  desktopBannerUpload?: string;
  desktopBannerName?: string;
  desktopBannerMetadata?: any;
  bannerUrl?: string;
  bannerBackgroundColour?: string;
  bannerPosition?: string | null;
  metadata?: Metadata;
  templateId?: number,
  templateDetails?: {
    id: number,
    name: string,
    templateType: string,
    templateTypeName: string
  },
  desktopBannerVariants?: {
    desktop_banner_variant_768: string;
    desktop_banner_variant_1280: string;
    desktop_banner_variant_1920: string;
    url: string;
  },
  mobileBannerVariants?:{
    mobile_banner_variant_360: string;
    mobile_banner_variant_414: string;
    mobile_banner_variant_601: string;
    url: string;
  }
}

export interface Metadata {
  categories?: Category[];
  services?: serviceDisplay[];
  isOpen?: boolean,
  sectionIdToSend?: string | number;
  display_location?: boolean;
  isTestimonial?: boolean;
  testimonialName?: string;
  isLocation?: boolean;
  locationName?: string;
  staff?: StaffDisplay[];
}

interface Category {
  category_name: string;
  category_image: string;
  selected: string;
  isSelected:boolean;
}

interface serviceDisplay {
    selected?: string,
    serviceAttrs: service | undefined,
}

export interface StaffDisplay {
  selected?: string,
  staffAttrs: StaffData | undefined,
}

interface service {
    id?: string,
    type?: string,
    attributes: {
        id: number | string,
        title: string,
        duration: number | string,
        discount_option: boolean,
        discount: number | string,
        price: number | string,
        currency: string | null | {
          id: string,
          name: string,
          symbol: string
        },
        actual_price: number | string,
        images: Array<
            {
                id: number |  string,
                url: string,
                webp_url: string | null
            }>
    }
}

export interface AllStaffData {
  data: Array<StaffData>
}

export interface StaffData {
  id: string;
  type: string;
  attributes: {
    id: number | string,
    full_name: string,
    designation: string,
    slug: string,
    image: string
  }
}

// Customizable Area End

// Customizable Area Start
export interface SS {
  id: string;
}
// Customizable Area End

class BrandingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollRef: React.RefObject<Scrollbars>;
  getStoreNameAPI: string = "";
  getCanvasDataAPI: string = "";
  getServiceDataAPI: string = "";
  getStaffDataAPI: string = "";
  getStoreDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = createRef();
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedTab: "mobile",
      createId: "",
      imageUrl: "",
      updateData: {
        footer: {
          phone_number: 5656565656,
          footerCopyRight: "",
          facebook_url: "",
          instagram_url: "",
          x_url: "",
          youtube_url: "",
          linkedin_url: "",
          appstore_url: "",
          playstore_url: "",
          design_id: "",
          is_social_media: false,
          is_app_link: false,
          is_contact: false
        },
        header: {
          storeName: '',
          isStoreNameEnabled: false,
          isLogoUploaded: false,
          selectedLogo: null,
          selectedFavicon: null,
          selectedLogoName:"",
          selectedFaviconName:"",
          isMobileMenuOpen: false,
          isFaviconUploaded: false,
          navigationMenuOptions: {
            menuData: configJSON.menuItems
          },
          templateId: NaN,
          templateDetails: {
            id: NaN,
            name: "",
            templateType: "",
          }
        },
        sections: {
          data: [{
            id: "",
            type: "",
            attributes: {
              section_type: "",
              section_name: "",
              banner_url: "",
              mobile_banner_url: "",
              desktop_banner_url: "",
              is_deleted: false,
              metadata: {
                isOpen: false
              },
              desktop_banner_variants: {
                url:  "",
                webp_url: "",
                768:  "",
                1280:  "",
                1920: "",
              },
              mobile_banner_variants: {
                url:  "",
                webp_url:  "",
                360: "",
                414: "",
                601:  "",
              }
            }
          }
          ]
        },
      },
      categoryData: [],
      isApiCalled: false,
      productData: [],
      storeName: "",
      sectionCategoryTitle: {
        sectionID: "",
        categoryTitle: "category"
      },
      token: "",
      selectedSection:"",
      expandedSection:"",
      SectionData: [],
      InitialSectionData: [],
      isLoading:false,
      servicesData:[],
      staffData: [],
      categoriesData:[],
      isTestmonial: false,
      textLogoMetaData: { fontType: "Lato, sans-serif", colour: '#000' },
      removeLogo: false,
      navigationItems: {} as HeaderMetadata,
      selectedColor: { primary: "", secondary: "", primarySecond: "", secondarySecond: ""},
      selectedFonts: { header: "", body: "" },
      storeEmail: "",
      buildCardID: "",
      countryName: "",
      isHeaderEnable: false,
      isBannerEnable: false,
      // updatedText: '',
      footerCopyRightError: '',
      currStoreName: ''
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("admintoken")
    let id = await getStorageData("id")
    const buildCardID = await storageProvider.get("buildCardID");
    const countryName = await storageProvider.get("countryName");
    this.setState({ token, buildCardID, countryName },()=>{
      this.featureFlag()
    })
    if (token.length) {
      this.getStoreNameAPI = this.apiCallHandle(token, configJSON.storeProfileEndPointAPI, configJSON.getApiMethod)
      this.getServiceData();
      this.getStaffData();
      this.getStoreDetails();
    }
    this.getCanvasDataAPI = this.apiCallHandle(token, configJSON.createContentWebsiteBuilderEndPoint, configJSON.getApiMethod)
    
    // Customizable Area End
  }

  // Customizable Area Start

  receive = (from: string, message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }

    this.getStoreNameApiCall(apiRequestCallId, responseJson);
    this.getCanvasApiCall(apiRequestCallId, responseJson);
    this.getServicesApiCall(apiRequestCallId, responseJson);
    this.getStaffApiCall(apiRequestCallId, responseJson);
    if (this.getStoreDetailsApiCallId === apiRequestCallId) {
      this.handleStoreDetailsResponse(responseJson)
    }
  };

  getStoreNameApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (this.getStoreNameAPI === apiRequestCallId) {
      if (responseJson?.data) {
        this.setState({ storeName: responseJson.data.attributes.name, storeEmail: responseJson.data.attributes.customer_support_email})
      }
    }
  }

  getCanvasApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (this.getCanvasDataAPI === apiRequestCallId) {
      if (responseJson?.data) {
        this.HandleCanvasDataGetAPIResponse(responseJson.data.attributes)
        storageProvider.set("designDetails", JSON.stringify(responseJson.data));
        // this.setState({ 
        //   storeName: responseJson?.data?.attributes?.header?.data?.attributes?.favicon_filename,
        // })
      }
    }
  }

  getServicesApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (this.getServiceDataAPI === apiRequestCallId) {
      if (responseJson.data) {
          this.setState({ servicesData:responseJson.data })
      } else {
        this.setState({ servicesData: [] })
      }
    }
  }

  getStaffApiCall = (apiRequestCallId: string, responseJson: AllStaffData) => {
    if (this.getStaffDataAPI === apiRequestCallId) {
      if (responseJson.data) {
          this.setState({ staffData:responseJson.data })
      } else {
        this.setState({ staffData: [] })
      }
    }
  }


  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab: selectedTab === 0 ? "mobile" : "desktop" });
  };

  creatIdHandler = (createId: string, responseJson: ProductPreviewContentInterface) => {
    this.setState({
      createId,
    })
    if(!responseJson?.data && responseJson?.footer){
      this.setState(prevState => ({
        updateData: {
          ...prevState.updateData,
         responseJson
        },
      }));
    }
  };

  realTimeHandler = (fieldValue: string | boolean, fieldName: string, fieldType: string, fieldLinkType: string) => {
    let tempData = { ...this.state.updateData }
    let updatedData: any = Object.fromEntries(
      Object.entries(tempData).map((item: any = {}) => {

        if (item[0] === fieldType) {
          item[1][fieldName] = fieldValue;
          if (fieldLinkType) {
            item[1][fieldLinkType] = true;
          }
        }
        return item
      })
    )
    this.setState({
      updateData: updatedData,
      isApiCalled: false
    })
  };

  HandleStoreNameEnabled = (isStoreNameTrigged: boolean) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isStoreNameEnabled: isStoreNameTrigged,
        },
      },
    }));
  };

  handleTestimonialNameEnabled = ( isTestimonialTrigged: boolean, sectionItemIndex: number ) => {
    this.setState(prevState => ({
      SectionData: prevState.SectionData.map((section, index) => {
        if (index === sectionItemIndex) {
          return {
            ...section,
            metadata:{
              ...section.metadata,
              isTestimonial: isTestimonialTrigged
            }
          };
        }
        else {
          return section;
        }
      })
    }));
  };

  handleLocationNameEnabled = (isLocationTrigged: boolean, sectionItemIndex: number) => {
    this.setState(prevState => ({
      SectionData: prevState.SectionData.map((section, index) => {
        if (index === sectionItemIndex) {
          return {
            ...section,
            metadata:{
              ...section.metadata,
              isLocation: isLocationTrigged
            }
          };
        }
        else {
          return section;
        }
      })
    }));
  };

  HandleNavigationMenuOptions = (APIResponse: any) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          navigationMenuOptions: {
            menuData: APIResponse
          }
        }
      }
    }))
  };

  HandleLogoUpload = (files: string) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isLogoUploaded: true,
          selectedLogo: files,
          isFaviconUploaded:true,
          selectedFavicon:files,

        },
      },
    }))
    this.setState({removeLogo:false})
  };

  HandleMobileMenyTrigger = (trigger: boolean) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          isMobileMenuOpen: trigger
        },
      },
    }))
  };

  HandleCanvasDataGetAPIResponse = (APIResponseUnFiltered: any) => {
    const currentYear = moment().format("YYYY");
    const storeName = this.state.currStoreName || "Studio Store";
    
    this.setState({navigationItems: 
      isJson(APIResponseUnFiltered.header.data.attributes.metadata) ? 
      JSON.parse(APIResponseUnFiltered.header.data.attributes.metadata) : 
      APIResponseUnFiltered.header.data.attributes.metadata
    })
    const setToCssRoot = function (vars: { [key: string]: string }) {
      for (const key in vars) {
        document.documentElement.style.setProperty(key, vars[key]);
      }
    }

    this.setState({SectionData: APIResponseUnFiltered.sections.data,InitialSectionData:APIResponseUnFiltered.sections.data});
    
    const { 
      primary_colour,
      secondary_colour,
      header_text,
      body_text 
    } = APIResponseUnFiltered.themes_and_font.data.attributes;

    setToCssRoot({
      '--primary-color': primary_colour,
      '--primary-color-opacity': `${primary_colour}99`,
      '--secondary-color': secondary_colour,
      '--accent-color': `${primary_colour}1A`,
      '--header-text-font': header_text,
      '--body-text-font': body_text
    });
    
    const APIResponse = APIResponseUnFiltered?.header?.data?.attributes;
    const APIResponses = APIResponseUnFiltered?.footer?.data?.attributes;
    const metadata = APIResponseUnFiltered?.metadata;
    const MenuItems = metadata && Object.keys(metadata).length !== 0 ? isJson(metadata) ? JSON.parse(metadata) : metadata : null;
    const sectionData = APIResponseUnFiltered.sections;
    this.handleTextLogoColorChange(APIResponse.logo_colour || '#000')
    this.handleTextLogoFontChange(APIResponse.logo_font || 'Inter')

    let mainData = APIResponseUnFiltered.sections?.data.find((item: any) => {
      return item.attributes.section_type === "category";
    });

    let isLogoUploaded:boolean;
    if(APIResponse?.logo_url !== null){
      if(APIResponse.logo_url.length){
        isLogoUploaded =true;
      }
      else{
        isLogoUploaded = false;
      }
    }
    else{
      isLogoUploaded = false;
    }
    let isFaviconUploaded:boolean;
    if(APIResponse?.favicon_url !== null){
      if(APIResponse.favicon_url.length){
        isFaviconUploaded =true;
      }
      else{
        isFaviconUploaded = false;
      }
    }
    else{
      isFaviconUploaded = false;
    }
    const val = "Copyright © Current Year Store Name. All rights reserved"
    const footerText = APIResponses?.copyright
    if (footerText === val) {
      console.log()
      const updatedFooterText = `Copyright © ${currentYear} ${storeName}. All rights reserved`;
      this.setState(
          prevState => ({
              updateData: {
                  ...prevState.updateData,
                  footer: {
                      ...prevState.updateData.footer,
                      footerCopyRight: updatedFooterText
                  }
              },
          }),
      );
  }
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        footer:{
          ...prevState.updateData.footer,
          is_contact:APIResponses?.is_contact === null ? false : APIResponses?.is_contact,
          is_social_media: APIResponses?.is_social_media === null ? false : APIResponses?.is_social_media,
          is_app_link:APIResponses?.is_app_link === null ? false : APIResponses?.is_app_link,
          facebook_url:APIResponses?.facebook_url === null ? false : APIResponses?.facebook_url,
          instagram_url:APIResponses?.instagram_url === null ? false : APIResponses?.instagram_url,
          x_url:APIResponses?.x_url === null ? false : APIResponses?.x_url,
          youtube_url:APIResponses?.youtube_url === null ? false : APIResponses?.youtube_url,
          playstore_url:APIResponses?.playstore_url  === null ? false : APIResponses?.playstore_url,
          appstore_url:APIResponses?.appstore_url  === null ? false : APIResponses?.appstore_url,
          // footerCopyRight:APIResponses?.copyright === null ? false : APIResponses?.copyright,
        },
        header: {
          ...prevState.updateData.header,
          isStoreNameEnabled: APIResponse?.show_business_name,
          
          isLogoUploaded: isLogoUploaded,
          isFaviconUploaded:isFaviconUploaded,
          selectedLogo: APIResponse?.logo_url,
          selectedFavicon: APIResponse?.favicon_url,
          isMobileMenuOpen: APIResponse?.is_mobile_menu,
          navigationMenuOptions: {
            menuData: MenuItems?.headerNavigationData?.menuData
          },
          templateId: APIResponse?.template_id,
          templateDetails: APIResponse.template_details

        },
        sections:sectionData,
        InitialSectionData: sectionData
      },
      sectionCategoryTitle: {
        categoryTitle: mainData?.attributes.section_type
      },
    }));
   
   this.HandleCanvasDataGetAPIResponse2(APIResponseUnFiltered)
  };

  HandleCanvasDataGetAPIResponse2 = (APIResponseUnFiltered:any)=>{
    if (APIResponseUnFiltered.sections?.data) {
      const sectionData = APIResponseUnFiltered.sections.data.map((section: any) => {
        let sectionInfo: any = {};

        switch (section?.attributes?.section_type?.toLowerCase()) {
          case 'service_display':
            sectionInfo = this.serviceDataHandle(section);
            break;
          case 'staff_display':
            sectionInfo = this.staffDataHandle(section);
            break;
          case 'banner':
            sectionInfo = this.bannerDataHandle(section);
            break;
          case "testimonial":
            sectionInfo = this.testimonialDataHandle(section);
            break;
          case "location":
            sectionInfo = this.locationDataHandle(section);
            break;
          default:
            break;
        }
        if (section.id && sectionInfo.metadata) {
          sectionInfo.metadata.sectionIdToSend = section.id;
        }
        return sectionInfo;
      });

      this.setState({
        SectionData: sectionData?.filter((item: any) => item),
        InitialSectionData: sectionData?.filter((item : any) => item),
        isLoading: false
      });
    }
  }
  resetSection = () => {
    this.setState({SectionData: this.state.InitialSectionData})
  }
  categoryDataHandle(section:any){
  return {
    type: 'category',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    sectionName: section.attributes.section_name,
    metadata: section.attributes.metadata && Object.keys(section.attributes.metadata).length ? JSON.parse(section.attributes.metadata) : { isOpen: false }
  }
}
serviceDataHandle(section:any){
  return {
    type: 'service_display',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    sectionName: section.attributes.section_name,
    metadata: section.attributes.metadata && Object.keys(section.attributes.metadata).length ? JSON.parse(section.attributes.metadata) : {}
  }
}
staffDataHandle(section:SectionsData){
  return {
    type: 'staff_display',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    sectionName: section.attributes.section_name,
    metadata: section.attributes.metadata && Object.keys(section.attributes.metadata).length ? JSON.parse(section.attributes.metadata) : {}
  }
}
bannerDataHandle(section:any){
  return {
    type: 'banner',
    id: section.id,
    is_deleted: section.attributes.is_deleted,
    bannerUrl: section.attributes.banner_url,
    mobileBannerUpload: getBannerURL(
      section.attributes.mobile_banner_url, 
      section.attributes.mobile_banner_variants
    ),
    desktopBannerUpload: getBannerURL(
      section.attributes.desktop_banner_url, 
      section.attributes.desktop_banner_variants
    ),
    mobileBannerName: section.attributes.mobile_banner_filename,
    mobileBannerMetadata: section.attributes.mobile_banner_metadata && Object.keys(section.attributes.mobile_banner_metadata).length ? JSON.parse(section.attributes.mobile_banner_metadata) : {},
    desktopBannerName: section.attributes.desktop_banner_filename,
    desktopBannerMetadata: section.attributes.desktop_banner_metadata && Object.keys(section.attributes.desktop_banner_metadata).length ? JSON.parse(section.attributes.desktop_banner_metadata) : {},
    metadata: isJson(section.attributes.metadata) ? JSON.parse(section.attributes.metadata) : {},
    bannerBackgroundColour: section.attributes.banner_background_colour,
    bannerPosition: section.attributes.banner_position,
    templateId: section.attributes.template_id,
    desktopBannerVariants: {
      desktop_banner_variant_768: section.attributes.desktop_banner_variants.desktop_banner_variant_768,
      desktop_banner_variant_1280: section.attributes.desktop_banner_variants.desktop_banner_variant_1280,
      desktop_banner_variant_1920: section.attributes.desktop_banner_variants.desktop_banner_variant_1920,
      url: section.attributes.desktop_banner_variants.url
    },
    mobileBannerVariants: {
      mobile_banner_variant_360: section.attributes.mobile_banner_variants.mobile_banner_variant_360,
      mobile_banner_variant_414: section.attributes.mobile_banner_variants.mobile_banner_variant_414,
      mobile_banner_variant_601: section.attributes.mobile_banner_variants.mobile_banner_variant_601,
      url: section.attributes.mobile_banner_variants.url
    }
  }
}
  realTimeSectionCategoryName = (sectionID: string, sectionName: string, section_type: string) => {

    this.setState({
      sectionCategoryTitle: {
        sectionID: sectionID,
        categoryTitle: sectionName
      }
    })
  }

  realTimeSectionHandler = (fieldValue: any, fieldType: string, section_type: string, fieldName: string) => {
    let tempData = { ...this.state.updateData }
    const imageUrl = URL.createObjectURL(fieldValue);
    let updatedData: any = {
      ...tempData,
      [fieldType]: {
        ...tempData[fieldType],
        data: tempData[fieldType].data.map((item: any, index: number) => {
          if (index === index) {
            item.attributes[fieldName] = imageUrl;
          }
          return item;
        }),
      },
    };
    this.setState({
      updateData: updatedData,
      isApiCalled: false
    })
  };

  realTimeCategoryHandler = (fieldValue: any) => {
    let categoryData = fieldValue.filter((item: any) => {
      return item.section_type === "category";
    })[0];

    let CategoryList = [];
    for (const key in categoryData) {
      if (key.startsWith("category")) {
        CategoryList.push({
          category_name: categoryData[key],
          category_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg"
        });
      }
    }
    this.setState({
      categoryData: CategoryList,
      isApiCalled: false
    })
  };

  realTimeProductHandler = (fieldValue: any) => {
    let productData = fieldValue.filter((item: any) => {
      return item.section_type === "product";
    })[0];

    let ProductList = [];
    for (const key in productData) {
      if (key.startsWith("product")) {
        ProductList.push({
          product_name: productData[key],
          product_image: "https://cdn.pixabay.com/photo/2016/06/03/17/35/shoes-1433925_1280.jpg",
          new_rate: "129,95",
          old_rate: "149,00",
          currency: "�",
          discount: "20%"
        });
      }
    }
    this.setState({
      productData: ProductList,
      isApiCalled: false
    })
  };
  handleTextLogoFontChange = (font: string) => {
    this.setState({
      textLogoMetaData: {
        ...this.state.textLogoMetaData,
        fontType: font
      }
    })
  }
  handleTextLogoColorChange = (color: string) => {
    this.setState({
      textLogoMetaData: {
        ...this.state.textLogoMetaData,
        colour: color
      }
    })
  }
  HandleTextLogoData = ()=>{
    this.setState({removeLogo:true, updateData: {...this.state.updateData,header: {...this.state.updateData.header,isStoreNameEnabled: true}}})
  }
  generateRandomString(length:number) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(randomValues[i] % characters.length);
    }
    return result;
  }
  handleSectionAdd = (type: string, name?: string): void => {
    let newSection: SectionDataInterfaceType;
 let randomString = this.generateRandomString(11);
    if (type.toLowerCase() === "banner") {
      newSection = {
        type: "banner",
        id: "banner_" + randomString + "_" + Date.now(),
        is_deleted: false,
        bannerUrl: '',
        mobileBannerUpload: '',
        mobileBannerName: '',
        mobileBannerMetadata: '',
        desktopBannerUpload: '',
        desktopBannerName: '',
        desktopBannerMetadata: '',
        bannerBackgroundColour: "",
        bannerPosition: null,
        metadata: {
            isOpen: true,
        },
        desktopBannerVariants: {
          desktop_banner_variant_768: "",
          desktop_banner_variant_1280: "",
          desktop_banner_variant_1920: "",
          url: ""
        },
        mobileBannerVariants: {
          mobile_banner_variant_360: "",
          mobile_banner_variant_414: "",
          mobile_banner_variant_601: "",
          url: ""
        },
        ...(name ? { sectionName: name } : {})
      };
    } else if (type.toLowerCase() === "service_display") {

      const demoData = {
        "id": "",
        "type": "top_service",
        "attributes": {
          "title": "",
          "duration": null,
          "status": null,
          "discount_option": null,
          "discount": null,
          "slug": "",
          "price": null,
          "currency": {
            "id": null,
            "name": "",
            "symbol": ""
          },
          "actual_price": null,
          "images": [
            {
              "id": null,
              "url": "",
              "webp_url": "",
              "small_url": "",
              "medium_url": ""
            }
          ]
        }
      }

      const fixedSizeArray = [...Array(4)].map((_, i) => this.state.servicesData[i] || demoData);
      
      const servicesArray: serviceDisplay[] = fixedSizeArray.map((service) => ({
        selected: service.id,
        serviceAttrs: service
      }));
      newSection = {
        type: "service_display",
        id: "Service_Display_" + randomString + "_" + Date.now(),
        is_deleted: false,
        sectionName: name || 'Service Display',
        metadata: {
          isOpen: true,
          services: servicesArray
        }
      };
    } else if (type.toLowerCase() === "staff_display") {

      const demoData = {
        "id": "",
        "type": "service_provider_list",
        "attributes": {
          "id": "",
          "full_name": "",
          "designation": "",
          "slug": "",
          "image": ""
        }
      }

      const fixedSizeArray = [...Array(6)].map((_, i) => this.state.staffData[i] || demoData);

      const staffArray: StaffDisplay[] = fixedSizeArray.map((staff) => ({
        selected: staff.id,
        staffAttrs: staff || null
      }))

      newSection = {
        type: "staff_display",
        id: "Staff_Display_" + randomString + "_" + Date.now() || "",
        is_deleted: false,
        sectionName: name || 'Staff Display',
        metadata: {
          isOpen: true,
          staff: staffArray
        }
      };
    }
    this.setState(prevState => ({
      SectionData: [...prevState.SectionData, newSection]
    }));

  }

  updateSectionType = (index: number, type: string) => {
    let updatedSectionData = [...this.state.SectionData];
    let sectionToUpdate = updatedSectionData[index];

    if (sectionToUpdate.type !== type) {
      if (type === "banner") {
        sectionToUpdate = {
          type: "banner",
          id: sectionToUpdate.id,
          is_deleted: false,
          bannerUrl: '',
          mobileBannerUpload: '',
          mobileBannerName: '',
          mobileBannerMetadata: '',
          desktopBannerUpload: '',
          desktopBannerName: '',
          desktopBannerMetadata: '',
          bannerBackgroundColour:"",
          bannerPosition: null,
          sectionName: 'Banner',
          metadata: {
            isOpen: true,
          },
          desktopBannerVariants: {
            desktop_banner_variant_768: "",
            desktop_banner_variant_1280: "",
            desktop_banner_variant_1920: "",
            url: ""
          }
        };
      } else if (type === "service_display") {
        const arrLength = this.state.servicesData.length > 4 ? 4 : this.state.servicesData.length;
        const servicesArray: serviceDisplay[] = this.state.servicesData.slice(0, arrLength).map((service) => ({
          selected: service.id,
          serviceAttrs: service
        }));
        sectionToUpdate = {
          type: "service_display",
          id: sectionToUpdate.id,
          is_deleted: false,
          sectionName: 'Service Display',
          metadata: {
            isOpen: true,
            services: servicesArray
          }
        };
      } else if (type === "staff_display") {
        const arrLength = this.state.staffData.length > 6 ? 6 : this.state.staffData.length;
        const staffArray: StaffDisplay[] = this.state.staffData.slice(0, arrLength).map((staff) => ({
          selected: staff.id,
          staffAttrs: staff
        }));
        sectionToUpdate = {
          type: "staff_display",
          id: sectionToUpdate.id,
          is_deleted: false,
          sectionName: 'staff Display',
          metadata: {
            isOpen: true,
            staff: staffArray
          }
        };
      }

      sectionToUpdate.type = type;

      updatedSectionData[index] = sectionToUpdate;

      this.setState({
        SectionData: updatedSectionData
      });
    }
  };

  updateSectionName = (sectionId: string, sectionItemIndex: number, newName: string, sectionName: string): void => {
    this.setState(prevState => ({
      SectionData: prevState.SectionData.map((section, index) => {
        if (index === sectionItemIndex) {
          return {
            ...section,
            sectionName: newName,
          };
        }
        else {
          return section;
        }
      })
    }));
  }

  updateNavigationName = (navigationItem: HeaderMetadata) => {
    this.setState({navigationItems: navigationItem})
  }

  deleteSection = (sectionIndex: number) => {
    this.setState(prevState => ({
      SectionData: prevState.SectionData.map((section, index) =>
        index === sectionIndex ? { ...section, is_deleted: true } : section
      )
    }));
  }

  updateBannerMobileData = (index: number, base64Image: string, fileName: string, isBannerType: string) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[index]};
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner' && bannerToUpdate.mobileBannerVariants != undefined  && bannerToUpdate.desktopBannerVariants!=undefined) {
          bannerToUpdate.mobileBannerUpload = base64Image;
          bannerToUpdate.mobileBannerName = fileName;
          bannerToUpdate.desktopBannerUpload = base64Image;
          bannerToUpdate.desktopBannerName = fileName;
          updatedSectionData[index] = bannerToUpdate;
          bannerToUpdate.mobileBannerVariants.mobile_banner_variant_601 = base64Image;
          bannerToUpdate.desktopBannerVariants.desktop_banner_variant_1920 = base64Image;
        }
        return { SectionData: updatedSectionData };
    });
  }

  updateBannerUrl = (index: number, bannerUrl: string) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[index]};
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner') {
        bannerToUpdate.bannerUrl = bannerUrl;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  updateBannerMobileMetadata = (index: number, metadata: any) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[index]};
      if (bannerToUpdate && bannerToUpdate?.type?.toLowerCase() === 'banner') {
        bannerToUpdate.mobileBannerMetadata = metadata;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  updateBannerDesktopMetadata = (index: number, metadata: any) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[index]};
      if (bannerToUpdate && bannerToUpdate?.type?.toLowerCase() === 'banner') {
        bannerToUpdate.desktopBannerMetadata = metadata;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  handleSectionDropDownOpen = (index: number) => {
    this.setState(prevState => {
      const updatedSectionData = prevState.SectionData?.map((section, i) => {
        if (i === index && section.metadata) {
           section.metadata.isOpen = !section?.metadata?.isOpen;
        } else if (section.metadata) {
          section.metadata.isOpen = false;
        }
        return section;
      });
      return { SectionData: updatedSectionData };
    });
  }

  handleCloseAllSections = () => {
    this.setState(prevState => {
      const updatedSectionData = prevState.SectionData.map(section => {
        if (section.metadata) {
          section.metadata.isOpen = false;
        }
        return section;
      });
      return { SectionData: updatedSectionData };
    });
  }  

  overrideCategoryOrProductSelectedValue = (sectionId: string, sectionType: string, index: number, selectedValue: any) => {
    const updatedSectionData = this.state.SectionData.map((section) => {
      if (section.id === sectionId && section.type.toLowerCase() === sectionType.toLowerCase()) {
        if (sectionType.toLowerCase() === "service_display" && section.metadata && section.metadata.services) {
          const updatedServices = section?.metadata?.services?.map((service: serviceDisplay, serviceIndex: number) => {
            if (serviceIndex === index) {
              const topServicesData = {
                "id": "",
                "type": "top_service",
                "attributes": {
                  "id": "",
                  "title": "",
                  "duration": "",
                  
                  "discount_option": false,
                  "discount": "",
                  
                  "price": "",
                  "currency": {
                    "id": "",
                    "name": "",
                    "symbol": ""
                  },
                  "actual_price": "",
                  "images": [
                    {
                      "id": "",
                      "url": "",
                      "webp_url": ""
                    }
                  ]
                }
              }
              if(selectedValue === ""){
                return {
                  ...service,
                  selected: selectedValue,
                  serviceAttrs: topServicesData
                };
              }else{                
                return {
                  ...service,
                  selected: selectedValue,
                  serviceAttrs: this.state.servicesData?.find((item:service) => item.id === selectedValue),
                };
              }
            }
            return service;
          });
          return {
            ...section,
            metadata: {
              ...section.metadata,
              services: updatedServices
            }
          };
        } else if (sectionType.toLowerCase() === "staff_display" && section.metadata && section.metadata.staff) {
          const updatedServices = section?.metadata?.staff?.map((service: StaffDisplay, staffIndex: number) => {
            const staffData = {
              "id": "",
              "type": "service_provider_list",
              "attributes": {
                "id": "",
                "full_name": "",
                "designation": "",
                "slug": "",
                "image": ""
              }
            }
            if (staffIndex === index) {
              if(selectedValue === ""){
              return {
                ...service,
                selected: selectedValue,
                staffAttrs: staffData
              };
            } else {
              return {
                ...service,
                selected: selectedValue,
                staffAttrs: this.state.staffData?.find((item:StaffData) => item.id === selectedValue),
              };
            }
          }
            return service;
          });

          return {
            ...section,
            metadata: {
              ...section.metadata,
              staff: updatedServices
            }
          };
        }
      }
      return section;
    });
    this.setState({ SectionData: updatedSectionData });
  };

  updateFooter = (eventName: string, value: boolean | string) => {
    console.log('update footer called')
    this.setState((prevState) => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          [eventName]: value,
        },
      },
    }));
  };

  openToastChange = () => {
    this.props.showHeaderBar({
      message: "",
      ignoreClickAway: true,
    });
  };

  async getStoreDetails() {
    const getStoreDetails = await generateRequestMessage(
      configJSON.getStoreDetailAPI,
      configJSON.getApiMethod
    );
    this.getStoreDetailsApiCallId = getStoreDetails.messageId;
    runEngine.sendMessage(getStoreDetails.id, getStoreDetails);
  }

  handleStoreDetailsResponse(responseJson: any) {
    const storeName = responseJson?.data?.attributes?.name || "Studio Store";
    const currentYear = moment().format("YYYY");
    if (responseJson?.data?.attributes?.name) {
      this.setState(prevState => ({
        currStoreName : storeName,
        updateData: {
          ...prevState.updateData,
          footer: {
            ...prevState.updateData.footer,
          }
        }
      }));
    }
  }

  updateFooterCopyRight = (newValCopyRight: string) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          footerCopyRight: newValCopyRight
        }
      }
    }));
  };

  handleChangeFooterCopyRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    let error = "";

    if (value.length > 150) {
      error = "Copyright must be at maximum of 150 characters";
    }

    if (value.length <= 150) {
      error = ""; 
    }

    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          footerCopyRight: value,
        }
      },
      footerCopyRightError: error
    }), () => {
      this.updateFooter(name, value)
      this.openToastChange()
    })
  };

  resetFooterCopyRight = () => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          footerCopyRight: '', 
        }
      },
      footerCopyRightError: '' 
    }));
  }

  apiCalledHandler = () => {
    this.setState({
      isApiCalled: true
    })
  };

  handleGetAPITrigger = async () => {
    this.setState({
      isLoading: true
    })
    this.getCanvasDataAPI = this.apiCallHandle(this.state.token, configJSON.createContentWebsiteBuilderEndPoint, configJSON.getApiMethod)
  }

  apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
    const header = { token: token };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), header);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), body)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSocialLinkToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState: any) => ({
      updateData: {
        ...prevState.updateData,
        footer: {
          ...prevState.updateData.footer,
          is_social_media: event,
        },
      },
    }))
  }

  updateSelectedSection = (sectionName: string) => {
    this.setState({ selectedSection: sectionName });
  }
  
  updateExpandedSection = (sectionName:string)=>{
    this.setState({expandedSection:sectionName});
  }

  getServiceData = async (searchQuery: string = "") => {
    this.getServiceDataAPI = this.apiCallHandle(this.state.token, `${configJSON.getServicesEndPointAPI}?search_query=${searchQuery}`, configJSON.getApiMethod)
  }

  getStaffData = async () => {
    this.getStaffDataAPI = this.apiCallHandle(this.state.token, configJSON.getStaffEndPointAPI, configJSON.getApiMethod)
  }

  testimonialDataHandle(section: { id: number; attributes: { is_deleted: boolean; section_name: string; metadata: string; customer_feedback :StaffData }; }){
    return {
      type: "testimonial",
      id: section?.id,
      is_deleted: section?.attributes?.is_deleted,
      sectionName: section?.attributes?.section_name,
      customer_feedback: section?.attributes?.customer_feedback,
      metadata: section?.attributes?.metadata  ? (isJson(section?.attributes?.metadata)? JSON.parse(section?.attributes?.metadata): section?.attributes?.metadata) : {}
    }
  };

  locationDataHandle(section: { id: number; attributes: { is_deleted: boolean; section_name: string; metadata: string; }; }){
    return {
      type: "location",
      id: section?.id,
      is_deleted: section?.attributes?.is_deleted,
      sectionName: section?.attributes?.section_name,
      metadata: section?.attributes?.metadata  ? (isJson(section?.attributes?.metadata)? JSON.parse(section?.attributes?.metadata): section?.attributes?.metadata) : {}
    }
  };

  handleBannerBackgroundColor = (color: string, curIndex: number) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[curIndex]};
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === "banner") {
        bannerToUpdate.bannerBackgroundColour = color;
        updatedSectionData[curIndex] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  };

  handleBannerPosition = (position: string, curIndex: number) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[curIndex]};
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === "banner") {
        bannerToUpdate.bannerPosition = position;
        updatedSectionData[curIndex] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  };

  handleDragAndDrop = (updatedArray: SectionDataInterfaceType[]) => {
    this.setState({ SectionData: updatedArray })
  };

  handleColors = (section: string, color: string) => {
    if(section === "primary"){
      this.setState({
        selectedColor: {
          ...this.state.selectedColor,
          primary: color,
          primarySecond: color + '1A'
        }
      });
    }else {
      this.setState({
        selectedColor: {
          ...this.state.selectedColor,
          secondary: color,
          secondarySecond: color + "A6"
        }
      });
    }
  };

  handleFonts = (section: string, font: string) => {
    if(section === "header"){
      this.setState({
        selectedFonts: {
          ...this.state.selectedFonts,
          header: font
        }
      });
    }else {
      this.setState({
        selectedFonts: {
          ...this.state.selectedFonts,
          body: font
        }
      });
    }
  };

  handleColorsSelection = (primary: string, secondary: string) => {
    this.setState({
      selectedColor: {
        ...this.state.selectedColor,
        primary: primary,
        primarySecond: primary + "1A",
        secondary: secondary,
        secondarySecond: secondary + "A6"
      }
    });
  };

  handleHeaderTemplate = (name: string) => {
    this.setState(prevState => ({
      updateData: {
        ...prevState.updateData,
        header: {
          ...prevState.updateData.header,
          templateDetails: {
            ...prevState.updateData.header.templateDetails,
            name: name
          }
        },
      },
    }));
  };

  handleBannerTemplate = (id: number, index: number) => {
    this.setState(prevState => {
      const updatedSectionData = [...prevState.SectionData];
      const bannerToUpdate = {...updatedSectionData[index]};
      if (bannerToUpdate && bannerToUpdate.type.toLowerCase() === 'banner') {
        bannerToUpdate.templateId = id;
        updatedSectionData[index] = bannerToUpdate;
      }
      return { SectionData: updatedSectionData };
    });
  }

  featureFlag = async () => {
    let identifier = this.state.buildCardID + "" + "exampleuser@builder.ai"

    const userObject = new configcat.User(
      identifier,
      "exampleuser@builder.ai",
      this.state.countryName,
      {
        "buildCardId": this.state.buildCardID,
        "Platform": "web",
        "URL": baseURL
      }
    );

    configCatClient
      .getValueAsync('headerfeatureflag', false, userObject)
      .then((value) => {
        this.setState({ isHeaderEnable: value })
      });

    configCatClient
      .getValueAsync('bannerfeatureflag', false, userObject)
      .then((value) => {
        this.setState({ isBannerEnable: value })
      });
  }
  // Customizable Area End
}

export default BrandingController;
