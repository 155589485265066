// Customizable Area Start
import React, { ChangeEvent, RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseHeader, parseLocation } from "../../utilities/src/helpers/utils";
import { Navigation } from "../../utilities/src/models/Navigation";
import { saveStoreDetails } from "../../utilities/src/components/Currency.web";
import storageProvider from "../../../framework/src/StorageProvider.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import { setStorageData } from "../../../framework/src/Utilities";
import { eventEmitter } from "../../../framework/src/utils/EventEmitter";
import StorageProvider from "../../../framework/src/StorageProvider";
import * as configcat from 'configcat-js';
import { configCatClient } from "../../../components/src/FeatureFlag";
export const configJSON = require("./config");
const baseURL = require("../../../framework/src/config.js").baseURL;

export interface Props {
  navigation: Navigation;
  identifier: string;
}

export interface OpeningHours {
  id: number,
  start_time: string,
  end_time: string,
  week_day: string
}

export interface OfflineDays {
  date: string,
  full_day: string,
  custom: {
    start_time: string,
    end_time: string
  }
}

export interface CommonSettingRes {
  commonSettingData: CommonSettingData
}

export interface CommonSettingData {
  id: string,
  type: string,
  attributes: {
    location_url: string,
    city: string,
    state: string,
    country: string,
    pin_code: string,
    address_line1: string,
    address_line2: string,
    time_zone: string,
    is_cancellation_policy: boolean,
    is_reschedule_policy: boolean,
    is_refund_policy: boolean,
    no_of_days_cancel: number,
    cancel_text: string,
    no_of_days_reschedule: number,
    reschedule_text: string,
    refund_text: string,
    online_booking: boolean,
    offline_alert_text: string,
    offline_days: Array<OfflineDays>,
    same_offline_time_for_all: boolean,
    show_message: boolean,
    image: {
      id: number,
      url: string
    },
    opening_hours: Array<OpeningHours>,
    time_zone_short: string,
    store_closing_days: string
  }
}

export interface CommonSetting {
  data: CommonSettingData
}

export interface ServiceList {
  id: number,
  title: string,
  is_service: boolean
}

export interface AppHeaderResponse {
  data: AppHeaderData
}

export interface bookingData {
  id: string,
  type: string,
  attributes: {
    test_order: boolean;
    order_number: string,
    status: string,
    total: number,
    service: {
      id: number,
      title: string,
      price: number,
      discount_price: string,
      discount_option: boolean,
      duration: number
    },
    service_images: {
      id: number,
      url: string,
      thumbnail_url: string,
      small_url: string,
      webp_url: string
    },
    payment_mode: string,
    currency: {
      id: number,
      name: string,
      symbol: string
    },
    updated_by_id: string,
    catalogue_price: number,
    timezone: string,
    time_zone_short: string,
    order_date: string
    appointment_date: string
    customer: {
      id: number,
      full_phone_number: string
      email: string
      full_name: string
      created_at: string
      updated_at: string
      appointment_id: number,
      comment: string
    },
    country: {
      id: number,
      name: string,
      code: string,
      phone_code: string,
    },
    service_provider: string,
    billing_address: string,
    cancellation_policy: {
      is_cancellation_policy: boolean,
      no_of_days_cancel: number,
      cancel_text: string,
    },
    reschedule_policy: {
      is_reschedule_policy: boolean,
      no_of_days_reschedule: number,
      reschedule_text: string,
    },
    refund_policy: {
      is_refund_policy: boolean,
      refund_text: string,
    }
  }
}

export interface serializer {
  data: [bookingData]
}

export interface manageBookingResponse {
  serializer: serializer,
  cancel_status: boolean,
  reschedule_status: boolean
}

export interface AppHeaderData {
  attributes: GetDesignData
}

export interface GetDesignData {
  themes_and_font: {
    data: {
      id: string,
      type: string,
      attributes: {
        primary_colour: string,
        secondary_colour: string,
        header_text: string,
        body_text: string,
        design_id: number
      }
    }
  },
  header: {
    data: {
      id: string,
      type: string,
      attributes: {
        show_business_name: boolean,
        is_mobile_menu: boolean,
        logo_url: string,
        favicon_url: string,
        logo_filename: string,
        favicon_filename: string,
        metadata: string,
        logo_colour: string,
        logo_font: string,
        custom_favicon: boolean;
        template_details: {
          name: string
        }
        template_id: string

      }
    }
  },
  footer: {
    data: {
      id: string,
      type: string,
      attributes: {
        copyright: string,
        facebook_url: string,
        x_url: string,
        youtube_url: string,
        linkedin_url: string,
        instagram_url: string,
        appstore_url: string,
        playstore_url: string,
        design_id: number,
        is_social_media: boolean,
        is_app_link: boolean,
        is_contact: boolean,
        phone_number: string,
        country_code: string,
        address: string
      }
    }
  },
  sections: {
    data: Array<SectionDataType>
  }
}

export interface DesignDataInt {
  designData: GetDesignData
}

export interface SectionDataType {
  id: string,
  type: string,
  attributes: SectionAttributes
}

interface SectionAttributes {
  section_type: string;
  section_name: string;
  banner_url: string;
  mobile_banner_url: string;
  mobile_banner_metadata: string;
  desktop_banner_url: string;
  desktop_banner_metadata: string;
  is_deleted: boolean;
  banner_filename: string;
  desktop_banner_filename: string;
  mobile_banner_filename: string;
  metadata: string;
  section_order: number;
  banner_background_colour: string;
  banner_position: string;
  mobile_banner_variants: {
    url: string;
    webp_url: string;
    360: string;
    414: string;
    601: string;
  };
  desktop_banner_variants: {
    url: string;
    webp_url: string;
    768: string;
    1280: string;
    1920: string;
  };
}

export interface SearchlistResponseInf {
  search_data: Array<ServiceList>
}

interface S {
  logo: string | null;
  storeName: string;
  searchQuery: string;
  searchRef: string;
  searchListSuggestions: Array<ServiceList>;
  openClose: boolean;
  isServiceProvider: boolean;
  renameItem1: string;
  renameItem2: string;
  renameItem3: string;
  show_business_name: boolean;
  isModalOpen: boolean;
  email: string;
  emailError: string;
  bookingId: string;
  bookingIdError: string;
  loading: boolean;
  buildCardID: string;
  color: string;
  font: string;
  isSearchEnabled: boolean;
  countryName: string;
  templateName: string;
  banner_background:string
}

interface SS {
  identifier: string;
}

export default class AppheaderController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string = "";
  getStoreDetailsCallId: string = "";
  searchServiceListAPICallId: string = "";
  getManageBookingAPICallId: string = "";
  getLocationApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchQueryMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationMessage),
    ];

    this.state = {
      openClose: false,
      logo: null,
      storeName: "",
      searchQuery: "",
      searchRef: "",
      searchListSuggestions: [],
      isServiceProvider: false,
      renameItem1: "",
      renameItem2: "",
      renameItem3: "",
      show_business_name: false,
      isModalOpen: false,
      email: '',
      emailError: '',
      bookingId: '',
      bookingIdError: '',
      loading: false,
      buildCardID: "",
      color: "",
      font: "",
      isSearchEnabled: false,
      countryName: "",
      templateName: "",
  banner_background:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const buildCardID = await storageProvider.get("buildCardID");
    this.setState({
      buildCardID: buildCardID
    });
    this.getBrands();
    this.getStoreDetails();
    this.getServiceProviderDetail();
    this.getLocation();

    window.addEventListener('popstate', this.handleBackButton);
  }

  async componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const navigationData = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      if (responseJson && !responseJson.errors && responseJson.data) {
        storageProvider.set("designDetails", JSON.stringify(responseJson.data));
        this.handleGetBrandsResponse(responseJson);
      } else {
        this.setState({
          logo: null,
          storeName: "",
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        mixpanel.track(`${this.state.buildCardID}_webcustomer_error_occurred_in_brands`,
          {
            error: errorReponse,
            buildCardID: this.state.buildCardID
          });

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.SearchQueryMessage) === message.id) {
      const searchQuery = message.getData("SearchQueryMessage").trim();
      this.setState({ searchQuery });
    } else if (this.getStoreDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (responseJson.data) {
        const storeData = responseJson?.data?.attributes;
        eventEmitter.dispatch('updateStoreData', {
          storeData: storeData
        });
        StorageProvider.set("countryCode", storeData?.country_code)
        StorageProvider.set("countryName", storeData?.country_name)
        StorageProvider.set("countryId", storeData?.country_id)

        this.getServiceProviderCount(storeData?.service_provider_available)
        window.location.href !== window.location.origin + "/" &&
          (document.title = storeData?.name || "SSServiceInternal");
        setStorageData(
          "store_name",
          storeData?.name
        );
        this.setState({
          buildCardID: storeData?.buildcard_name,
          storeName: storeData?.name,
          countryName: storeData?.country_name
        }, () => this.featureFlag())
        storageProvider.set("buildCardID", storeData?.buildcard_name);
        saveStoreDetails(storeData);
      } else {
        mixpanel.track(`${this.state.buildCardID}${configJSON.errorInStorageDetails}`,
          {
            error: responseJson.errors[0],
            buildCardID: this.state.buildCardID
          });
      }
    } else if (this.searchServiceListAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.error) {
        this.handlesearchServiceListResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        mixpanel.track(`${this.state.buildCardID}_webcustomer_error_occurred_in_search_srvice_list`,
          {
            error: errorReponse,
            buildCardID: this.state.buildCardID
          });

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (this.getManageBookingAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleManageBookingResponse(responseJson);
      } else if (responseJson.errors) {
        this.handleManageBookingResponse(responseJson);
        mixpanel.track(`${this.state.buildCardID}_webcustomer_error_occurred_in_manage_booking`,
          {
            error: responseJson.errors,
            buildCardID: this.state.buildCardID
          });
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLocationApiCallId != null &&
      this.getLocationApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetLocationResponse(responseJson);
      } else if (responseJson.errors) {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (navigationData && navigationData?.openBookingModal) {
      this.toogleModal();
    }

  };

  handleGetLocationResponse = (responseJson: CommonSetting) => {
    const response = responseJson?.data;

    storageProvider.set("commonSettings", JSON.stringify(parseLocation(response)))

    eventEmitter.dispatch('updateCommonSettings', {
      commonSettingData: response
    });
  };

  getLocation = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getLocationApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getLocationEndPoint,
      header,
    });
  };

  handleGetBrandsResponse = (responseJson: AppHeaderResponse) => {
    const response = responseJson.data;
    const { logo, renameItem1, renameItem2, renameItem3, show_business_name, color, font } = parseHeader(response, this.state.storeName);
    const themes_and_font = response.attributes.themes_and_font;
    const header = response.attributes.header;
    const footer = response.attributes.footer;
    const sections = response.attributes.sections;
    const templateName = response.attributes.header.data.attributes.template_details.name
  
    const banner_background=response.attributes.sections.data[0].attributes.banner_background_colour
    storageProvider.set('sectionsData', JSON.stringify(sections));
    StorageProvider.set("navigationData", header.data.attributes.metadata);

    this.setState({
      logo,
      renameItem1,
      renameItem2,
      renameItem3,
      show_business_name,
      color,
      font,
      templateName: templateName,
  banner_background:banner_background
    });
    console.log("@@@1233", color, font, this.state.templateName)

    eventEmitter.dispatch('updateDesignDetails', {
      designData: { themes_and_font, header, footer, sections }
    });
  };

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header
    });
  };

  confirmSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
    const screen = this.isPreviewMode ? "AdvancesearchPreview" : "Advancesearch"
    this.props.navigation.navigate(screen, {
      searchQuery
    });
  };

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStoreDetailsCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStoreDetails,
      header
    });
  }

  searchServiceList = (queryRef: RefObject<HTMLInputElement>) => {
    this.setState({
      openClose: true,
      loading: true
    })
    if (queryRef.current) {
      this.setState({ searchRef: queryRef.current?.value })
    }

    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const query = (queryRef.current && queryRef.current.value)
    this.searchServiceListAPICallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.searchServiceListAPIEndPoint + "?query=" + decodeURIComponent(String(query)),
      header,
    });
  };

  handlesearchServiceListResponse = (responseData: SearchlistResponseInf) => {

    this.setState({ searchListSuggestions: responseData.search_data, loading: false })

  }

  handleNavigation = (route: string) => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(navigationMessage.messageId, navigationMessage);
  }
  isPreviewMode = window.location.href.includes("/preview");
  handleManageBookingResponse = (responseData: manageBookingResponse) => {
    if (responseData?.serializer?.data?.[0]?.attributes?.test_order) {
      storageProvider.remove("bookingData")
    } else {
      storageProvider.set("bookingData", JSON.stringify(responseData));
    }
    this.toogleModal();
    this.setState({ email: '', bookingId: '' })
    this.handleNavigation(this.isPreviewMode ? "OrderStatusPreview" : "OrderStatus")
  }

  searchElementTitleFunction = (searchQuery: string, isService: boolean) => {
    this.setState({
      openClose: false,
    })
    if (isService) {
      this.confirmSearchService(searchQuery);
    } else {
      this.confirmSearchProvider(searchQuery)
    }
  };

  confirmSearchService = (searchQuery: string) => {
    const screen = this.isPreviewMode ? "ServicesSearchPreivew" : "ServicesSearch"
    this.props.navigation.navigate(screen, {
      searchQuery
    });
    this.setState({ searchQuery });
  };

  confirmSearchProvider = (searchQuery: string) => {
    const screen = this.isPreviewMode ? "StaffSearchPreview" : "StaffSearch"
    this.props.navigation.navigate(screen, {
      searchQuery
    });
    this.setState({ searchQuery });
  };

  closeSearch = () => {
    this.setState({
      openClose: false,
      loading: false
    })
  }

  getServiceProviderCount = (isAvailable: boolean) => {
    this.setState({ isServiceProvider: isAvailable })
    window.localStorage.setItem("serviceProvider", JSON.stringify(isAvailable));
  }

  getServiceProviderDetail = () => {
    const serviceProviderDetails = localStorage.getItem("serviceProvider");
    if (serviceProviderDetails && serviceProviderDetails !== "undefined") {
      this.setState({ isServiceProvider: JSON.parse(serviceProviderDetails) });
    }
  }

  toogleModal = () => {
    mixpanel.track(`${this.state.buildCardID}_${configJSON.navigationManageBooking}`, { buildCardID: this.state.buildCardID });
    this.setState({ email: '', bookingId: '', emailError: '', bookingIdError: '' })
    this.setState({ isModalOpen: !this.state.isModalOpen, loading: false }, () => {
      if (this.state.isModalOpen) {
        setTimeout(() => {
          document.body.style.cssText = 'overflow: hidden !important';
        }, 0);
      }
    })
  }

  isValidEmail = (value: string) => {
    if (value === '') {
      this.setState({ emailError: configJSON.emailRequired })
      return false;
    } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
      this.setState({ emailError: configJSON.invalidEmail })
      return false;
    } else {
      this.setState({ emailError: '' })
      return true;
    }
  }

  isValidBookingId = (value: string) => {
    if (value === '') {
      this.setState({ bookingIdError: configJSON.bookingIdRequired })
      return false;
    }
    else {
      this.setState({ bookingIdError: '' })
      return true;
    }
  }

  isValidButtonSubmit = () => {
    const { email, bookingId } = this.state;
    let isValidEmail = false, isValidBookingId = false;
    isValidEmail = this.isValidEmail(email);
    isValidBookingId = this.isValidBookingId(bookingId)
    return isValidEmail && isValidBookingId
  }


  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.isValidEmail(event.target.value)
    this.setState({ email: event.target.value })
  }
  handleBookingIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.isValidBookingId(event.target.value)
    this.setState({ bookingId: event.target.value })
  }

  searchBookingDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getManageBookingAPICallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.getManageBookingEndPoint}?email=${this.state.email}&order_id=${this.state.bookingId}`,
      header
    });
  }

  handleSubmit = () => {
    if (this.isValidButtonSubmit()) {
      mixpanel.track(`${this.state.buildCardID}_${configJSON.proceedManageBookingForm}`, { buildCardID: this.state.buildCardID });
      this.searchBookingDetails()
    }
  }

  handleBackButton = (event: { type: string }) => {
    if (event.type === 'popstate') {
      this.setState({ email: '', bookingId: '', emailError: '', bookingIdError: '' })
      this.setState({ isModalOpen: false })
      setTimeout(() => {
        document.body.style.cssText = 'overflow: auto';
      }, 0);
    }
  }

  featureFlag = async () => {
    let identifier = this.state.buildCardID + "" + "exampleuser@builder.ai"

    const userObject = new configcat.User(
      identifier,
      "exampleuser@builder.ai",
      this.state.countryName,
      {
        "buildCardId": this.state.buildCardID,
        "Platform": "web",
        "URL": baseURL
      }
    );

    configCatClient
      .getValueAsync('searchfeatureflag', false, userObject)
      .then((value) => {
        this.setState({ isSearchEnabled: value })
      });
  }
}
// Customizable Area End
