import React, { useState } from "react";
// Customizable Area Start
import { CardMedia, Slide, styled, Box, Button, Typography, TextField, FormControl, IconButton, CircularProgress, Select, InputLabel, Checkbox } from "@material-ui/core";
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import Calendar from "react-calendar";
import { StudioIcon,ErrorIcon, closeIcon, CalendarIcon, listViewIcon } from "./assets";
import moment from 'moment';
import { deletIcon,RightCheck } from './assets'
import "react-calendar/dist/Calendar.css";
import '../../appointmentmanagement/src/cal.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarViewController, { ServiceProviders } from "./CalendarViewController.web";
import { KeyboardArrowDown, KeyboardArrowUp, ArrowForward, ArrowBack } from "@material-ui/icons";
import "../../../../packages/blocks/ordermanagement/src/calendarView.css"
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import { ArrowDropDown, CalendarTodayOutlined } from "@material-ui/icons";
import Currency from "../../utilities/src/components/Currency.web";
import moment_timezone from 'moment-timezone';
// Customizable Area End

// Customizable Area Start

const StyledButton = styled(Typography)(() => ({
    textTransform: 'none',
    minWidth: 0,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    cursor: "pointer !important",
    fontFamily: [
        "Rubik"
    ].join(','),
    '&.Mui-selected': {
        color: "#6200EA",
        fontWeight: 600,
        borderBottom: '1px solid #6200EA',
        borderRadius: "none"
    },
    '&.Mui-unselected': {
        color: "#83889E",
        borderRadius: "none",
        fontWeight: 600,
    },
}));

const configJSON = require("./config");

export interface ITimeZone {
    name: string;
  } 
  
  const time_zones=localStorage.getItem("timeZone") || "";
  moment_timezone.tz.setDefault(time_zones);
  moment.updateLocale('en', {
    week: {
        dow: 1, 
        doy: 1,
    },
});
  const localizer = momentLocalizer(moment_timezone);

const CustomHeader = ({ date }: any) => {
    const day = moment(date).format('ddd');
    const dayNumber = moment(date).format('DD');

    return (
        <div>
            <div className="day">{day}</div>
            <div className="date">{dayNumber}</div>
        </div>
    );
};

const CustomToolBar = ({ onView }: any) => {
    const [activeView, setActiveView] = useState('day');

    const handleButtonClick = (view: any) => {
        setActiveView(view);
        onView(view);
    };
    
    return (
        <Box display="flex" mb={2} marginTop="39px" marginLeft="22px" marginRight="480px" width="100%">
            <Box display="flex" width="855px" borderBottom="1px solid #E8E8E8" >
                <Box marginRight="18.06px">
                    <StyledButton onClick={() => handleButtonClick('day')}
                        className={activeView === 'day' ? 'Mui-selected' : 'Mui-unselected'}>
                        Daily
                    </StyledButton>
                </Box>
                <Box>
                    <StyledButton onClick={() => handleButtonClick('week')}
                        className={activeView === 'week' ? 'Mui-selected' : 'Mui-unselected'} style={{ marginLeft: 10 }}>
                        Week
                    </StyledButton>
                </Box>

            </Box>
        </Box>

    )
};
// Customizable Area End

export class CalendarViewList extends CalendarViewController {
    // Customizable Area Start
    sliding = () => {
        const { selectedId } = this.state;
        return (
            <Slide direction="down" in={this.state.isFormChanged} mountOnEnter unmountOnExit style={{ left: 0, right: 0 }}>
                <Box style={webStyles.formActionsWrapper as React.CSSProperties}>
                    <Box style={webStyles.formActions}>
                        <CardMedia
                            component="img"
                            src={StudioIcon}
                            style={webStyles.logo}
                        />

                        <Box>
                            <Button
                                variant="text"
                                style={webStyles.discardChangesbutton}
                                onClick={this.discardChanges}
                            >
                                Discard changes
                            </Button>
                            <Button
                                variant="contained"
                                style={webStyles.saveChangesbutton}
                                onClick={() => this.updateEditAppointment(selectedId)}
                            >
                                Save changes
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        )
    };

    dateIsDisabled = ({ date, view }: { date: Date, view: string }) => {
        const today = new Date();
        if (view === 'month' && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
            return false;
        }
        return false;
    };

    tileClassName = ({ date, view }: any) => {
        const { highlightedDate } = this.state;
        if (
            view === 'month' &&
            highlightedDate &&
            date.getDate() === highlightedDate.getDate() &&
            date.getMonth() === highlightedDate.getMonth() &&
            date.getFullYear() === highlightedDate.getFullYear()
        ) {
            return 'react-calendar__tile--highlight';
        }
        return null;
    };

    CustomEvent = ({ event }: any) => {
        const { customerFullName, serviceTitle, staffName, appointmentTime, startTime, service, status, duration } = event;
        return (
            <div
                style={{
                    cursor: "pointer",
                    width: "107px",
                    height: `${duration}px`,
                    overflow: "hidden",
                    padding: '5px',
                    borderRadius: '5px',
                    zIndex: 1,
                    backgroundColor: status === "placed" ? "rgb(255, 251, 215)" : status === "cancelled" ? "rgb(255, 236, 236)" : "rgb(255, 243, 224)",
                    border: status === "placed" ? "1px solid rgb(244, 232, 117)" : status === "cancelled" ? "1px solid rgb(255, 215, 215)" : "1px solid rgb(255, 209, 128)"
                }}
            >
                <div>
                    <Typography variant="body2" style={{
                        fontWeight: 500, fontFamily: "Rubik",
                        color: "#000000", lineHeight: "12px", fontSize: "10px"
                    }}>
                        {
                            this.state.showFiltersData ?
                                staffName :
                                customerFullName
                        }
                    </Typography>
                    <Typography variant="body2" style={{
                        fontWeight: 400, fontSize: "8px", fontFamily: "Rubik",
                        color: "#000000", lineHeight: "12px"
                    }}>
                        {this.state.showFiltersData ? appointmentTime :
                            startTime
                        }
                    </Typography>

                    <Typography variant="body2" style={{
                        fontWeight: 400, fontSize: "8px", fontFamily: "Rubik",
                        color: "#000000", lineHeight: "12px"
                    }}>
                        {this.state.showFiltersData ?
                            serviceTitle
                            : service}
                    </Typography>

                </div>
            </div>
        );
    };
  
    render() {
        const { showFiltersData, filterData, serviceSlotBooking, staffListData, appointmentSummaryData, selectedDate } = this.state;
        const specificFilterData = this.filterOrdersByDate(filterData.length > 0 ? filterData : serviceSlotBooking,selectedDate?.toString());
        let date = new Date(specificFilterData[0]?.attributes.appointment_date);
        const slotStartTime = specificFilterData[0]?.attributes?.time_slot?.slot_start_time;
        let hours = "";
        let minutes = "";
        if (slotStartTime!==undefined){
            [hours, minutes] = slotStartTime?.split(':');
        }
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        
        
        
        const events = Array.isArray(serviceSlotBooking)
        ? serviceSlotBooking.filter((value) => value.attributes.status != "payment_failed" && value.attributes.status != "pending").map((item) => {
            const appointmentDate = new Date(item?.attributes?.appointment_date.replace("Z",""));
            const [date] = item?.attributes?.appointment_date.split('T');
            const endDate = new Date(appointmentDate.getTime() + item?.attributes?.service?.duration * 60 * 1000);
            
            return ({
                id: item?.id,
                timeSlotDate: item.attributes.appointment_date,
                title: item.attributes.customer.full_name,
                staffName: item?.attributes?.service_provider?.full_name,
                customerFullName: item.attributes?.service_provider?.full_name,
                startTime: moment(appointmentDate).format('hh:mm A'),
                service: item?.attributes?.service?.title,
                catalogueId: item?.attributes?.service?.id,
                serviceProviderId: item?.attributes?.service_provider?.id,
                start: appointmentDate,
                end: endDate,
                status: item.attributes.status,
                duration: item?.attributes?.service?.duration
            })
            })
            : [];

            const filterEvents = filterData?.filter((value) =>  value?.attributes?.status != "payment_failed" && value?.attributes?.status != "pending").map((item) => {
                const startTime = new Date(item?.attributes?.appointment_date.replace("Z",""));
                const [date] = item?.attributes?.appointment_date.split('T');
                const endTime = new Date(startTime.getTime() + item?.attributes?.service?.duration * 60 * 1000);
                
                return {
                    id: item.id,
                    timeSlotDate: item?.attributes?.appointment_date,
                    title: item?.attributes?.customer?.full_name,
                    staffName: item?.attributes?.service_provider?.full_name,
                    appointmentTime: moment(startTime).format('hh:mm A'),
                    serviceTitle: item?.attributes?.service?.title,
                    catalogueId: item?.attributes?.service?.id,
                    serviceProviderId: item?.attributes?.service_provider?.id,
                    start: startTime,
                    end: endTime,
                    status: item?.attributes?.status,
                    duration: item?.attributes?.service?.duration,
                    timeSlotId: item?.attributes?.time_slot?.id
                };
            })
            
            const showEvents: any = showFiltersData ? filterEvents : events
            const serviceData = appointmentSummaryData?.service?.title ? (`${appointmentSummaryData?.service?.title} ${appointmentSummaryData?.service?.duration && appointmentSummaryData?.service?.duration} mins,${appointmentSummaryData?.currency?.symbol}${appointmentSummaryData?.service?.price.toFixed(2)}`) : ""
            
            const filterScrollTime = () =>{
                const showEventsTime =showEvents.filter((item:any)=>
                new Date(item.timeSlotDate).getUTCFullYear() === new Date(moment(this.state.selectedDate).toDate()).getUTCFullYear() &&
                new Date(item.timeSlotDate).getUTCMonth() === new Date(moment(this.state.selectedDate).toDate()).getUTCMonth() &&
                new Date(item.timeSlotDate).getUTCDay() === new Date(moment(this.state.selectedDate).toDate()).getUTCDay() )
                  let earliestTimeSlot = showEventsTime[0];

                  for (let slot of showEventsTime) {
                    if (new Date(slot.timeSlotDate) < new Date(earliestTimeSlot.timeSlotDate)) {
                         earliestTimeSlot = slot;
                       }
                    }
                    const hours = new Date(earliestTimeSlot?.timeSlotDate)?.getUTCHours();
                    return isNaN(hours) ? 0 : hours;
            }
            return (
                <>
                <Slide direction="down" in={!!this.state.deletionAppointmentError} mountOnEnter unmountOnExit >
                    <Box style={webStyles.notificationWrapper}>
                        <Box style={webStyles.notification}>
                            <CardMedia
                                component="img"
                                src={require("../../servicemanagementAdmin/assets/Builder Studio Store.png")}
                                style={webStyles.logo1}
                                />
                            <Box style={webStyles.notificationMessage}>
                                {this.state.deletionAppointmentError && (
                                    <>
                                        <img
                                            src={ErrorIcon}
                                            style={webStyles.notificationIcon}
                                            alt="image loading"
                                        />
                                        <Typography style={webStyles.notificationText}>
                                            <strong>Error! </strong>
                                            {this.state.deletionAppointmentError}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Slide>
                <Slide direction="down" in={this.state.updateAppointmentSuccess} mountOnEnter unmountOnExit>
                    <Box style={webStyles.notificationWrapper}>
                        <Box style={webStyles.notification}>
                            <CardMedia
                                component="img"
                                src={require("../../servicemanagementAdmin/assets/Builder Studio Store.png")}
                                style={webStyles.logo1}
                            />
                            <Box style={webStyles.notificationMessage}>
                                {this.state.updateAppointmentSuccess && (
                                    <>
                                        <img
                                            src={RightCheck}
                                            style={webStyles.notificationIcon}
                                            alt="image loading"
                                        />
                                        <Typography style={webStyles.notificationText}>
                                            <strong>Success! </strong>
                                            {this.state.cancelAppointmentStatus == "cancelled" ? "Appointment cancelled successfully" : "Appointment updated successfully"}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Slide>
                {this.state.showDeleteDialog && (
                    <Box
                        style={{
                            ...webStyles.dialogDeleteContainer, position: "fixed",
                            flexDirection: "column",
                            zIndex: 2
                        }}
                    >
                        <Box style={{ margin: "40px" }}>
                            <Box
                                style={webStyles.dialogDeleteTitle}
                            >
                                <Typography
                                    variant="h6"
                                    style={webStyles.dialogDeleteTitleText}
                                >
                                    Are you sure?
                                </Typography>
                                <IconButton
                                    data-test-id="close-dialog"
                                    edge="end"
                                    color="inherit"
                                    onClick={this.handleDeleteDialogClose}
                                    aria-label="close"
                                    style={webStyles.closeBtn}
                                >
                                    <img style={webStyles.closeIcon} src={closeIcon} alt="close" />
                                </IconButton>
                            </Box>
                            <Typography
                                style={webStyles.content}
                            >
                                If you delete this appointment, the customer will receive an email confirming the cancellation.
                            </Typography>
                        </Box>
                        <Box style={webStyles.dialogButton} >
                            <Button
                                data-test-id="cancel-btn"
                                style={{ ...webStyles.cancelButton, textTransform: "none" }}
                                onClick={this.handleDeleteDialogClose}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                data-test-id="delete-btn"
                                style={{ ...webStyles.deleteImageButton, textTransform: "none" }}
                                onClick={() => this.handleConfirmDelete(this.state.selectedId)}
                            >
                                Yes, delete
                            </Button>
                        </Box>
                    </Box>
                )}
                <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="40px">
                    <Box width="122px" height="32px" marginBottom="50px" marginLeft="22px">
                        <Typography style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#3C3E49" }}>Bookings</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" marginRight="24px">
                        <Box marginRight="26px">
                            {this.state.isCsvDownloading ?
                                <Box width="50px"><CircularProgress size="24px" /></Box> :
                                <Typography
                                    data-test-id="download-csv"
                                    variant="h4"
                                    style={webStyles.customButton}
                                    onClick={() => { this.download() }}
                                >
                                    Download CSV
                                </Typography>}
                        </Box>
                        <Box>
                            <Button variant="outlined" style={{ textTransform: "none", width: "181px", height: "40px", backgroundColor: "#00D659", color: "#3C3E49", fontFamily: "Rubik", fontSize: "12px", lineHeight: "20px", fontWeight: 500 }} onClick={this.navigateToCreateBooking}>{configJSON.createBookingText}</Button>
                        </Box>
                    </Box>
                </Box>
                
                <Box style={webStyles.subContainerTwo}>
                    <Button style={{ ...webStyles.gridFilterBtn, cursor: "pointer", textTransform: "none" }} variant="outlined" startIcon={<img style={webStyles.headerIcon}
                        src={listViewIcon}
                        alt="list"
                    />} onClick={this.handleNavigateToOrderList}>List view</Button>
                    <Button startIcon={<img src={CalendarIcon}
                        alt="view" style={webStyles.headerIcon} />} style={{ ...webStyles.listFilterBtn, cursor: "pointer", textTransform: "none" }} onClick={this.handleNavigateToCalendarList}
                    >Calendar view</Button>
                </Box>
                <Box>
                    {this.sliding()}
                </Box>
                <Box width="100%" display="flex">
                    <Box flex={3} width="646px" height="742px">
                        {
                            (this.state.isLoading && serviceSlotBooking?.length > 0) ?
<BigCalendar
    style={{outline : "none !important"}}
    localizer={localizer}
    events={showEvents}
    startAccessor="start"
    endAccessor="end"
    showMultiDayTimes={true}
    defaultView="day"
    views={['day', 'week']}
    date={moment(this.state.selectedDate).toDate()}
    components={{
        week: { header: CustomHeader },
        day: { header: CustomHeader },
        event: this.CustomEvent,
        toolbar: CustomToolBar,
    }}
    onSelectEvent={this.handleEventClick}
    onSelectSlot={slotInfo => alert(`Selected slot: \n\nstart ${slotInfo.start.toLocaleString()} \nend: ${slotInfo.end.toLocaleString()}`)}
    titleAccessor={'title'}
    allDayAccessor={'allDay'}
                                scrollToTime={new Date(Number(year), Number(month), Number(day), filterScrollTime(), 0,0)}
                            />
                            :
                            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="742px">
                                <CircularProgress size={50} />
                            </Box>
                        }
                    </Box>
                    {!this.state?.showEditForm &&
                        <Box flex={1} height="742px" overflow={"scroll"}
                            minWidth="354px" sx={{
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', marginTop: "61px", bgcolor:"#fff"
                            }}>
                            <Box marginTop="40px" marginLeft="24px">
                                <Typography style={{ color: "#3C3E49", fontWeight: 500, fontFamily: "Rubik", fontSize: "28px", lineHeight: "32px" }}>View</Typography>
                            </Box>
                            <Box width="350px" marginTop="36px" >
                                <Calendar
                                    value={this.state.selectedDate}
                                    onChange={(value) => this.handleDateChange(value)}
                                    tileDisabled={this.dateIsDisabled}
                                    tileClassName={this.tileClassName}
                                    next2Label={null}
                                    prev2Label={null}
                                    nextLabel={
                                        <ArrowForward style={{ color: "#676B7E" }} />
                                    }
                                    prevLabel={
                                        <ArrowBack style={{ color: "#676B7E" }} />
                                    }
                                />
                            </Box>
                            <Box
                                style={{ marginLeft: "40px" }}
                            >
                                <Box marginTop="40px">
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            fontFamily: "Rubik",
                                            lineHeight: "24px",
                                            color: "#3C3E49"
                                        }}
                                    >Filter by</Typography>
                                </Box>
                                <Box>
                                    <Box display="flex" flexDirection="row" onClick={() => this.setState({ isOpenFilter: !this.state.isOpenFilter })}>
                                        <Box>
                                            <Typography style={{
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                fontFamily: "Rubik",
                                                lineHeight: "18.96px",
                                                marginTop: "40px",
                                                color: "#000000"
                                            }}>Staff</Typography>
                                        </Box>
                                        <Box style={{ marginLeft: '210px', marginTop: "24px" }}>
                                            {this.state.isOpenFilter ?
                                                <Box style={{ width: "24px", height: "24px", marginTop: "11px" }}>
                                                    <KeyboardArrowUp style={{ cursor: "pointer", width: "22.5px", height: "28.94px", color: "#000000" }} />
                                                </Box>
                                                :
                                                <Box style={{ width: "24px", height: "24px", marginTop: "11px" }}>
                                                    <KeyboardArrowDown style={{ cursor: "pointer", width: "22.5px", height: "28.94px", color: "#000000" }} />
                                                </Box>}
                                        </Box>
                                    </Box>
                                    {this.state.isOpenFilter && (
                                        <Box marginTop="25px">
                                            {staffListData?.map((item: any, index: number) => {
                                                return (
                                                    <Box marginLeft="-10px" display="flex" flexDirection="row" marginTop="4px">
                                                        <Box
                                                            onClick={() => this.handleCheckboxClick(item?.id)}
                                                        >
                                                            <Checkbox
                                                                style={{ marginTop: "-10px" }} />
                                                        </Box>
                                                        <Box>
                                                            <Typography style={{
                                                                fontFamily: "Rubik",
                                                                color: "#000000", fontSize: "12px", fontWeight: 500, lineHeight: "20px"
                                                            }} key={index}>{item.attributes.full_name}</Typography>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )}
                                </Box>
                                <Box borderBottom="1px solid #DFE0E6" marginTop="24px" width="280px" />

                                <Box>
                                    <Box display="flex" flexDirection="row" onClick={() => this.setState({ isOpenServices: !this.state.isOpenServices })}>
                                        <Box>
                                            <Typography style={{
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                lineHeight: "18.96px",
                                                marginTop: "21px",
                                                fontFamily: "Rubik",
                                                color: "#000000"
                                            }}>Services</Typography>
                                        </Box>
                                        <Box style={{ marginLeft:"183px" }}>
                                            {this.state.isOpenServices ? <Box style={{ width: "24px", height: "24px", marginTop: "18px", }}>
                                                <KeyboardArrowUp style={{ cursor: "pointer", width: "22.5px", height: "28.94px", color: "#000000" }} />
                                            </Box>
                                                :
                                                <Box style={{ width: "24px", height: "24px", marginTop: "18px", }}>
                                                    <KeyboardArrowDown style={{ cursor: "pointer", width: "22.5px", height: "28.94px", color: "#000000" }} />
                                                </Box>}
                                        </Box>
                                    </Box>
                                    {this.state.isOpenServices && (
                                        <Box marginTop="25px">
                                            {this.state.servicesList.map((item: any, index: number) => {
                                                return (
                                                    <>
                                                        <Box marginLeft="-10px" display="flex" flexDirection="row" marginTop="4px">
                                                            <Box onClick={(event) => {
                                                                this.handleServiceCheckboxClick(item.id);
                                                            }}>
                                                                <Checkbox style={{ marginTop: "-10px" }} />
                                                            </Box>
                                                            <Box>
                                                                <Typography style={{
                                                                    fontFamily: "Rubik",
                                                                    color: "#000000", fontSize: "12px", fontWeight: 500, lineHeight: "20px"
                                                                }} key={index}>{item.attributes.title}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                )
                                            })}
                                        </Box>
                                    )}
                                </Box>

                                <Box borderBottom="1px solid #DFE0E6" marginTop="24px" width="280px" />

                            </Box>
                        </Box>}
                    {this.state?.showEditForm &&
                        <Box display="flex" height="742px" overflow={"scroll"} flexDirection="column" width="354px" p={2} marginLeft="20px" marginTop="61px" bgcolor={"#fff"}>
                            <Box marginTop="40px" >
                                <Typography style={{ cursor: "pointer", fontSize: "12px" }} onClick={() => this.setState({ showEditForm: !this.state.showEditForm })}>
                                    <ArrowBack style={{height: "18px", width: "25px"}}/> Back to calendar view
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row"  >
                                <Box marginTop="4px">
                                    <Typography variant="h5" style={{color:"#3C3E49"}}>Edit appointment</Typography>
                                </Box>
                                <Box marginTop="9px" marginLeft="26%" style={{ cursor: "pointer" }} onClick={() => this.setState({ showDeleteDialog: !this.state.showDeleteDialog })}>
                                    <img src={deletIcon} alt="deleteIcon" style={{ cursor: "pointer" }} />
                                </Box>
                            </Box>
                            <Box marginTop="39px" marginBottom="16px">
                                <FormControl fullWidth variant="outlined" margin="normal">
                                    <InputLabel
                                        shrink
                                        style={{
                                            color: "#000000",
                                            lineHeight: "20px",
                                            fontFamily: "Rubik",
                                            fontWeight: 500,
                                            marginTop: "-14px",
                                            marginLeft: "-14px"
                                        }}
                                    >Service*
                                    </InputLabel>
                                    <TextField
                                        value={serviceData}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <ArrowDropDown />
                                            ),
                                            style: {
                                                borderRadius: "3px",
                                                width: "306px",
                                                height: "48px"
                                            }
                                        }}
                                        disabled
                                    />
                                </FormControl>
                            </Box>
                            {this.state.serviceProviderData &&
                                <>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <label
                                                htmlFor="time-select"
                                                style={{
                                                    color: '#000000',
                                                    lineHeight: '20px',
                                                    fontFamily: "Rubik",
                                                    fontSize: '12px',
                                                    fontWeight: 500,
                                                    marginTop: '-5.2px',
                                                }}
                                            >
                                                Staff*
                                            </label>
                                            <select
                                                value={this.state.serviceProviderId}
                                                defaultValue={this.state.serviceStaffId}
                                                onChange={this.handleStaffChange}
                                                onFocus={this.handleFieldFocus}
                                                style={{
                                                    borderRadius: "3px",
                                                    border: '1px solid #ccc',
                                                    width: '306px',
                                                    height: '48px',
                                                    appearance: 'none',
                                                    WebkitAppearance: 'none',
                                                    MozAppearance: 'none',
                                                    backgroundColor: this.state.isDisable ? '#F3F3F3' : 'white',
                                                    padding: '2px 15px',
                                                    color: "#3C3E49",
                                                    cursor: "pointer"
                                                }}
                                                disabled={this.state.isDisable}
                                            >
                                            {this.state.editAppointmentStaff.map((option: ServiceProviders, index: number) => {
                                                return (
                                                    <option style={{ cursor: "pointer" }} key={index} value={option.id}>
                                                        {option.full_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                            <ArrowDropDown
                                                style={{
                                                    position: 'relative',
                                                    left: '84%',
                                                    top: '-23px',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            <Box>
                                <Typography
                                    style={{
                                        color: "#000000",
                                        lineHeight: "20px",
                                        fontFamily: "Rubik",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        marginBottom: "-12px",
                                        marginTop: "-8px"
                                    }}
                                >Date
                                </Typography>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={webStyles.datePicker}
                                        inputVariant="outlined"
                                        format="eee, dd/MM/yyyy"
                                        placeholder="Select date"
                                        defaultValue={this.formatDFateWithOrdinal(this.state.selectedFieldDate)}
                                        margin="normal"
                                        onChange={this.handleFieldDateChange}
                                        onFocus={this.handleFieldFocus}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        keyboardIcon={<CalendarTodayOutlined onClick={() => this.setState({ isFormChanged: true })} />}
                                        value={this.formatDFateWithOrdinal(this.state.selectedFieldDate)}
                                        TextFieldComponent={(props) => (
                                            <TextField {...props} style={{ ...props.style, ...webStyles.datePicker }} />
                                        )}
                                        FormHelperTextProps={{
                                            style: { display: "none" },
                                        }}
                                        disabled={this.state.isDisable}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            {
                                !this.state.serviceProviderData &&
                                <div style={{ margin: '17px 0' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <label
                                            htmlFor="time-select"
                                            style={{
                                                color: '#000000',
                                                lineHeight: '20px',
                                                fontFamily: "Rubik",
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                marginTop: '-14px',
                                            }}
                                        >
                                            Time*
                                        </label>
                                        <select
                                            id="time-select"
                                            value={this.state.selectedTimeDate}
                                            onChange={this.handleTimeChange}
                                            onFocus={this.handleFieldFocus}
                                            style={{
                                                borderRadius: "3px",
                                                border: '1px solid #ccc',
                                                width: '306px',
                                                height: '48px',
                                                appearance: 'none',
                                                WebkitAppearance: 'none',
                                                MozAppearance: 'none',
                                                backgroundColor: this.state.isDisable ? '#F3F3F3' : 'white',
                                                padding: '2px 15px',
                                                color: "#3C3E49",
                                                cursor: "pointer"
                                            }}
                                            disabled={!!this.state.isSlotPresent || this.state.isDisable}
                                        >
                                            {
                                                this.state.serviceTimeSlotData.map((option: any, index: any) => {
                                                    const formatTime = (time: string) => {
                                                        const [hours, minutes] = time.split(':').map(Number);
                                                        const date = new Date();
                                                        date.setHours(hours);
                                                        date.setMinutes(minutes);

                                                        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
                                                        const hour12 = date.getHours() % 12 || 12;
                                                        const minutesStr = date.getMinutes().toString().padStart(2, '0');

                                                        return `${hour12}:${minutesStr} ${ampm}`;
                                                    };

                                                    const startTime = formatTime(option.slot_start_time);
                                                    const endTime = formatTime(option.slot_end_time);

                                                    return (
                                                        option.is_available &&
                                                        <option style={{ cursor: "pointer" }} key={index} value={option.id}>
                                                            {startTime} - {endTime}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                        <ArrowDropDown
                                            style={{
                                                position: 'relative',
                                                left: '84%',
                                                top: '-23px',
                                                transform: 'translateY(-50%)',
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.isSlotPresent && <p style={{ fontSize: '14px', color: "red", marginTop: "-15px" }}>No slots present</p>
                                    }
                                </div>
                            }
                            {
                                this.state.serviceProviderData &&
                                <div style={{ marginTop: '21px', marginBottom: "15px" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <label
                                            htmlFor="time-select"
                                            style={{
                                                color: '#000000',
                                                lineHeight: '20px',
                                                fontFamily: "Rubik",
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                marginTop: '-14px',
                                            }}
                                        >
                                            Time*
                                        </label>
                                        <select
                                            id="time-select"
                                            value={this.state.selectedTimeDate}
                                            onChange={this.handleTimeChange}
                                            onFocus={this.handleFieldFocus}
                                            disabled={!!this.state.isSlotPresent}
                                            style={{
                                                borderRadius: "3px",
                                                border: '1px solid #ccc',
                                                width: '306px',
                                                height: '48px',
                                                appearance: 'none',
                                                WebkitAppearance: 'none',
                                                MozAppearance: 'none',
                                                backgroundColor: this.state.isDisable ? '#F3F3F3' : 'white',
                                                padding: '2px 15px',
                                                color: "#3C3E49",
                                                cursor: "pointer"
                                            }}
                                        >
                                            {this.state.staffTimeSlotData.map((option: any, index: number) => {
                                                const formatTime = (time: string) => {
                                                    const [hours, minutes] = time.split(':').map(Number);
                                                    const date = new Date();
                                                    date.setHours(hours);
                                                    date.setMinutes(minutes);

                                                    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
                                                    const hour12 = date.getHours() % 12 || 12;
                                                    const minutesStr = date.getMinutes().toString().padStart(2, '0');

                                                    return `${hour12}:${minutesStr} ${ampm}`;
                                                };

                                                const startTime = formatTime(option.slot_start_time);
                                                const endTime = formatTime(option.slot_end_time);

                                                return (
                                                    option.is_available &&
                                                    <option style={{ cursor: "pointer" }} key={index} value={option.id}>
                                                        {startTime} - {endTime}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <ArrowDropDown
                                            style={{
                                                position: 'relative',
                                                left: '84%',
                                                top: '-23px',
                                                transform: 'translateY(-50%)',
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.isSlotPresent && <p style={{ fontSize: '14px', color: "red", marginTop: "-15px" }}>No slots present</p>
                                    }
                                </div>
                            }

                            <Box style={{borderTop: "1px solid #F5F5F5"}}/>

                            <Box mb={2} style={{ margin: "30px 0" }}>
                                <Typography style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "20px", lineHeight: "28px" }}>Customer details</Typography>
                                <Box>
                                <Typography
                                    onClick={() => this.handleCusDetPage(appointmentSummaryData?.customer?.id)}
                                    style={{
                                        fontFamily: "Rubik",
                                        marginTop: "15px",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        color: "#6200EA",
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}>
                                        {appointmentSummaryData?.customer?.full_name}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography style={{ fontFamily: "Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "black" }}>
                                        {appointmentSummaryData?.customer?.email}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography style={{ fontFamily: "Rubik", fontStyle: "italic", fontWeight: 400, fontSize: "14px", lineHeight: "24px" }}>
                                        {appointmentSummaryData.customer?.comment}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box style={{borderTop: "1px solid #F5F5F5"}}/>

                            <Box style={{ marginTop: "30px" }}>
                                <Typography style={{ fontFamily: "Rubik", fontWeight: 500, fontSize: "20px", lineHeight: "28px" }}>Order details</Typography>
                                <Box>
                                    <Typography style={{ color: "#000000",marginTop:"15px", fontFamily: "Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px" }}>
                                        <Currency
                                            text={`${appointmentSummaryData.service?.price.toFixed(2)}`}
                                            disountPrice=""
                                            time=""
                                            hasDiscount={false}
                                        />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography style={{ fontFamily: "Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#000000" }}>
                                        {appointmentSummaryData.payment_mode ? (appointmentSummaryData.payment_mode == "pay_later" ? "Pay later at location" : "Online")
                                            : 'N/A'}
                                    </Typography>
                                </Box>
                                <Box onClick={this.navigateToOrderDetails}>
                                    <Typography style={{ cursor: "pointer", color: "#6200EA", fontFamily: "Rubik", fontWeight: 400, fontSize: "14px", lineHeight: "24px", textDecoration: "underline" }} >
                                        View order details
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>}

                </Box>
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default CalendarViewList

const webStyles = {
    logo1: {
        position: "absolute" as "absolute",
        width: "159px",
        objectFit: "none" as "none",
        height: "calc(100% - 20px)",
    },
    notificationMessage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#3C3E49",
        height: "48px",
        flex: 1,
    },
    notificationIcon: {
        color: "transparent",
        marginRight: "10px"
    },
    notificationText: {
        fontSize: "14px",
        lineHeight: "20px",
        color: "#FFFFFF",
    },
    notificationWrapper: {
        top: 0,
        left: 0,
        position: "fixed" as "fixed",
        width: "100%",
        zIndex: 26,
    },
    notification: {
        display: "flex",
        backgroundColor: "#3C3E49",
        alignItems: "center",
        padding: "10px 20px"
    },
    mainContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: "#F8F9FA",
        margin: "40px 0px"
    },
    timeStyle: {
        marginLeft: "auto !important",
        width: "auto !important",
        bottom: "auto !important",
        opacity: "0.9 !important",

    },
    dialogDeleteContainer: {
        width: "684px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.16)",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
    },
    dialogDeleteTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "24px"
    },
    dialogDeleteTitleText: {
        fontFamily: "Rubik",
        fontSize: "28px",
        fontWeight: 500,
        color: "#3C3E49",
        lineHeight: "32px"
    },
    deleteImageButton: {
        padding: "20px 48px 20px 48px",
        borderRadius: "0px",
        background: "#00D659",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFFFFF",
        lineHeight: "24px"
    },
    hidden: {
        display: "none"
    },
    formActionsWrapper: {
        top: 0,
        position: "fixed",
        width: "100%",
        zIndex: 26,
    },
    formActions: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#3C3E49",
        padding: "10px 20px"
    },
    logo: {
        objectFit: "none" as "none",
        width: "auto"
    },
    discardChangesbutton: {
        fontSize: "14px",
        lineHeight: "20px",
        textDecoration: "underline" as "underline",
        textTransform: "none" as "none",
        padding: '8px 16px',
        color: "#FFFFFF",
        fontFamily: 'Rubik',

    },
    saveChangesbutton: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "none" as "none",
        padding: "10px 20px",
        backgroundColor: "#00D659",
        color: "#3C3E49",
        fontFamily: 'Rubik, sans-serif',
    },

    datePicker: {
        width: "306px", 
        height: "48px !important", 
        borderRadius: "3px",
        '& .MuiInputBase-root': {
            height: '48px !important',
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px',
        },
        '& .MuiFormHelperText-root': {
            display: "none !important"
        }

    },

    customButton: {
        color: "#3C3E49",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        textDecoration: "underline solid #3C3E49",
        marginTop: "10px",
        cursor: "pointer !important",
        marginLeft: "30px",
        lineHeight: "20px",
    },
    subContainerOne: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: "auto"
    },
    subContainerTwo: {
        display: 'flex',
        justifyContent: 'end',
        marginRight: "24px",
        marginTop: "-14px"
    },
    galleryText: {
        margin: '15px 0px',
        fontFamily: "Rubik",
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "32px",
    },
    uploadButton: {
        backgroundColor: "#00D659",
        margin: "15px 0px",
        color: "#3C3E49",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 500,
        width: "126px",
        height: "40px",
    },
    gridFilterBtn: {
        backgroundColor: "#fff",
        border: "1px solid #D0D2DA",
        color: "#83889E",
        margin: "15px 0px",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 600,
    },
    listFilterBtn: {
        backgroundColor: "#fff",
        border: "1px solid #D0D2DA",
        color: "#6200EA",
        margin: "15px 0px",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 600,
    },
    tableBodyDesign: {
        border: "4px solid #ECEDF0",
    },
    tableRowDesign: {
        border: "4px solid #ECEDF0",
    },
    tableHeader: {
        backgroundColor: "#F8F9FA",
        border: "3px solid #F8F9FA",
    },
    cellHeading: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#676B7E",
    },
    cellContent: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#3C3E49",
    },
    checkbox: {
        width: "24px",
        height: "24px",
        borderRadius: "2px"
    },
    deleteButton: {
        color: "#FF1744",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: 500,
        textDecoration: "underline solid #FF1744",
        marginTop: "30px",
        cursor: 'pointer',
        marginLeft: "10px"
    },
    headerIcon: {
        width: "16px",
        height: "16px",
        color: "grey"
    },
    editIcon: {
        width: "18px",
        height: "18px"
    },
    dialogContainer: {
        width: '684px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        zIndex: 999
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: '24px'
    },
    dialogTitleText: {
        fontFamily: "Rubik",
        fontSize: "28px",
        fontWeight: 500,
        color: "#3C3E49",
    },
    closeBtn: {
        margin: "0px",
        padding: "0px",
    },

    cancelButton: {
        padding: "20px 48px 20px 48px",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 400,
        color: "#3C3E49",
        textDecoration: "underline"
    },
    content: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
        color: "#3C3E49",
        marginBottom: "24px"
    },
    closeIcon: {
        width: "24px",
        height: "24px"
    },
    dialogButton: {
        borderTop: " 1px solid #E8E8E8",
        padding: "0px",
        marginTop: "40px",
        display: "flex",
        justifyContent: "flex-end"
    }
};
// Customizable Area End

