import React from "react";
// Customizable Area Start
import AppheaderController, { Props } from "./AppheaderController.web";
import Appheader from "./Appheader.web";
// Customizable Area End

export default class AppheaderWrapper extends AppheaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Appheader
        logo={this.state.logo}
        storeName={this.state.storeName}
        confirmSearch={this.confirmSearch}
        searchQuery={this.state.searchQuery}
        searchServiceList={this.searchServiceList}
        searchListSuggestionData={this.state.searchListSuggestions}
        searchElementTitleFunction={this.searchElementTitleFunction}
        searchRef1={this.state.searchRef}
        openClose={this.state.openClose}
        closeSearch={this.closeSearch}
        isServiceProvider={this.state.isServiceProvider}
        renameItem1={this.state.renameItem1}
        renameItem2={this.state.renameItem2}
        renameItem3={this.state.renameItem3}
        show_business_name={this.state.show_business_name}
        toogleModal={this.toogleModal}
        isModalOpen={this.state.isModalOpen}
        handleEmailChange={this.handleEmailChange}
        handleBookingIdChange={this.handleBookingIdChange}
        handleSubmit={this.handleSubmit}
        emailError={this.state.emailError}
        bookingIdError={this.state.bookingIdError}
        loading={this.state.loading}
        buildCardID={this.state.buildCardID}
        handleNavigation={this.handleNavigation}
        color={this.state.color}
        font={this.state.font}
        isSearchEnabled={this.state.isSearchEnabled}
        templateName={this.state.templateName}
        banner_background={this.state.banner_background}
      />
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
