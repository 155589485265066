Object.defineProperty(exports, '__esModule', {
    value: true
});

// Customizable Area Start
exports.getCurrentPlanMethodType = 'GET';
exports.getCurrentPlanAPI = [`https://api-staging-builder.engineer.ai/api/v1/build_cards/`,"__MARKER__",`/upgrade_plan_prices`];
exports.getCurrentPlanAppToken = 'iCxBWGyfgL89NPCwkc-i';
exports.PreviewWebsite = "Preview Website";
exports.previewPageLink = process.env.HOST_URL
exports.errorGetCurrentPlan="webadmin_error_occurred_get_current_plan";
// Customizable Area End