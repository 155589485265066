// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Appfooter = makeStyles((theme: Theme) => ({
  phoneNumber: {
    all: "unset", cursor: "pointer",
    color: "#E2E8F0",
    fontFamily: "var(--website-builder-body-font)"
  },
  tableContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 80px",
    background: "#222222",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(3),
      gap: theme.spacing(3.125),
      height: "auto",
    }
  },
 
  whatappIcon:{
    marginRight:"20px",
    padding:"20px",
    zIndex:1000,
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    textAlign:"center",
    borderRadius:"50%",
    backgroundColor: "#13c656",
    height:"80px",
    width:"80px",
    position: "fixed",
    color:"white",
    bottom: "20px",
    right: "10px"
  },
  tableContainerWrapper: {
    background: "#222222"
  },
  table: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    background: "#222222",
    gap: theme.spacing(12.5),
    height: "265px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(3.125),
      height: "auto",
    }
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  followUsColumn:{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(1120)]: {
      marginLeft:'20px'

    }
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    "& button": { padding: theme.spacing(0) },
    [theme.breakpoints.down(960)]: {
      gap: theme.spacing(2),
      flexDirection:'row !important'

    },
    [theme.breakpoints.down(1120)]: {
      flexDirection:'column'

    }
  },
  header: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: theme.spacing(2),
    color: "#F1F5F9",
    fontFamily: "var(--website-builder-body-font)",
  },
  email: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: theme.spacing(2),
    color: "#F1F5F9",
    fontFamily: "var(--website-builder-body-font)",
    marginTop:"20px"
  },
  header1:{
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: theme.spacing(2),
    color: "#000000",
     visibility:"hidden"
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: "column",
    }
  },
  titleBox:{
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  helpCenter: {
      display: "grid",
      gridTemplateColumns:"200px auto",
      gridTemplateRows: "auto auto",
      gridGap: "10px"
  },
  helpCenters: {
    marginLeft:"30px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "repeat(2,auto)",
      columnGap: "57px",
    marginLeft:"0px",
    marginTop:"5px"
    }
  },
  info: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#E2E8F0",
    fontFamily: "Lato, sans-serif",
    textDecoration: "none"
  },
  sendMessage: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#E2E8F0",
    fontFamily: "var(--website-builder-body-font)",
    textDecoration: "none"
  },
  icon: {
    color: "#FFFFFF"
  },
  twitterIcon: {
    height: "23px",
    width: "25px",
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1.5),
      flexDirection: "row"
    },
    "& .MuiCardMedia-media": {
      '-webkit-logical-width': 'auto',
      '-webkit-width': 'auto'
    },
  },
  image: {
    height: "40px",
    cursor: "pointer"
  },
  imageSkeltonBox:{
    display: "flex",
    height: "45px",
    width:"150px",
    borderRadius:'8px',
    [theme.breakpoints.down("sm")]: {
      height: "45px",
      width:"150px",
      borderRadius:'8px', 
    },
  },
  copyrights: {
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    lineHeight: "24px",
    whiteSpace: "pre",
    color: "#F1F5F9",
    fontWeight: 400,
    fontFamily: "var(--website-builder-body-font)",
    '& .MuiButton-root': {
      color: "#3C5291",
      textDecoration: "underline",
      textTransform: "none",
      fontSize: "10px",
      lineHeight: "24px",
      padding: "0",
      
    },
    '& .builder-link': {
      '& .first-letter': {
        textTransform: "lowercase",
      }
    }
  },

  iconBtn: {
    border: "1px solid #ffffff",
    borderRadius: "8px",
    width: "42px",
    height: "44px"
  }
}));
// Customizable Area End